/* istanbul ignore file */

// URL Polyfills
!(function(n) {
    n.URLSearchParams = n.URLSearchParams || function(n) {
        const e = this;

        e.searchString = n, e.get = function(n) {
            const r = new RegExp(`[?&]${n}=([^&#]*)`).exec(e.searchString);

            return r == null ? null : decodeURI(r[1]) || 0;
        };
    };
}(window));

// Window performance Polyfills
!(function(e) {
    const n = Date.now ? Date.now() : +new Date(); const r = e.performance || {}; const t = []; const a = {}; const i = function(e, n) {
        for (var r = 0, a = t.length, i = []; r < a; r++)t[r][e] == n && i.push(t[r]);

        return i;
    }; const o = function(e, n) { for (var r, a = t.length; a--;)(r = t[a]).entryType != e || void 0 !== n && r.name != n || t.splice(a, 1); };

    r.now || (r.now = r.webkitNow || r.mozNow || r.msNow || function() { return (Date.now ? Date.now() : +new Date()) - n; }), r.mark || (r.mark = r.webkitMark || function(e) {
        const n = {
            name: e, entryType: 'mark', startTime: r.now(), duration: 0,
        };

        t.push(n), a[e] = n;
    }), r.measure || (r.measure = r.webkitMeasure || function(e, n, r) {
        n = a[n].startTime, r = a[r].startTime, t.push({
            name: e, entryType: 'measure', startTime: n, duration: r - n,
        });
    }), r.getEntriesByType || (r.getEntriesByType = r.webkitGetEntriesByType || function(e) { return i('entryType', e); }), r.getEntriesByName || (r.getEntriesByName = r.webkitGetEntriesByName || function(e) { return i('name', e); }), r.clearMarks || (r.clearMarks = r.webkitClearMarks || function(e) { o('mark', e); }), r.clearMeasures || (r.clearMeasures = r.webkitClearMeasures || function(e) { o('measure', e); }), e.performance = r, typeof define === 'function' && (define.amd || define.ajs) && define('performance', [], () => { return r; });
}(window));

// ResizeObserver polyfill
(async () => {
    if ('ResizeObserver' in window === false) {
        // Loads polyfill asynchronously, only if required.
        const module = await import('@juggle/resize-observer');
        window.ResizeObserver = module.ResizeObserver;
    }
})();
