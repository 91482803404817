/* istanbul ignore file */

require('./polyfills');

let appLoaded = false;

const onAppLoaded = () => {
    if (appLoaded) return;

    try {
        require('./pendo');
        require('./myfonts');
    } catch (e) {}

    appLoaded = true;
};

if (window.addEventListener) {
    window.addEventListener('appLoaded', onAppLoaded, false);
} else if (window.attachEvent) {
    window.attachEvent('appLoaded', onAppLoaded);
} else {
    window.appLoaded = onAppLoaded;
}
