/* istanbul ignore file */
/* eslint-disable */

// Snippet used to track usage of myfonts.com fonts, our license includes a certain number of views.
const css = '@import url("//hello.myfonts.net/count/36ed97#.css")';
const style = document.createElement('style');

style.type = 'text/css';

if (style.styleSheet) {
    style.styleSheet.cssText = css;
} else {
    style.appendChild(document.createTextNode(css));
}

document.head.appendChild(style);
