/* istanbul ignore file */
/* eslint-disable */

!(function(e, n, t, i, a) {
    if (process.env.VUE_APP_LOGGING !== 'disabled') {

        let c; let o; let d; let s; let p;

        for ((a = e[i] = e[i] || {})._q = [], o = 0, d = (c = ['initialize', 'identify', 'updateOptions', 'pageLoad']).length; o < d; ++o)!(function(e) { a[e] = a[e] || function() { a._q[e === c[0] ? 'unshift' : 'push']([e].concat([].slice.call(arguments, 0))); }; }(c[o])); (s = n.createElement(t)).async = !0, s.src = `https://cdn.pendo.io/agent/static/${process.env.VUE_APP_PENDO_API_KEY}/pendo.js`, (p = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, p);
    }
}(window, document, 'script', 'pendo'));
